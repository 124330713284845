var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"wall",staticClass:"masonry-wall",style:({ display: 'flex', gap: `${_vm.columnGap}px` })},_vm._l((_vm.columns),function(column,columnIndex){return _c('div',{key:columnIndex,staticClass:"masonry-column",style:({
			display: 'flex',
			'flex-basis': `${_vm.getColumnWidthTarget(columnIndex)}px`,
			'flex-direction': 'column',
			'flex-grow': 1,
			gap: `${_vm.columnGap}px`,
			'row-gap': `${_vm.rowGap}px`,
			height: ['-webkit-max-content', '-moz-max-content', 'max-content'],
			'min-width': 0,
		}),attrs:{"data-index":columnIndex}},_vm._l((column),function(itemIndex){return _c('div',{key:itemIndex,staticClass:"masonry-item"},[_vm._t("default",function(){return [_vm._v("\n\t\t\t\t"+_vm._s(_vm.items[itemIndex])+"\n\t\t\t")]},{"item":_vm.items[itemIndex],"index":itemIndex})],2)}),0)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }