
	import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

	@Component({
		name: 'VirtualMasonry',
	})
	export default class VirtualMasonry extends Vue {
		@Prop({ required: true }) items: Array<any>;
		@Prop({ required: true }) itemHeightGetter: Function;
		@Prop({ required: true }) columns: number;
		@Prop({ default: 16 }) columnGap: number;
		@Prop({ default: 16 }) rowGap: number;
		@Prop({ default: false }) recycleNode: Boolean;

		colWidth = 200;
		rowsPerSection = 3;
		groupSize = 600;
		positionMap = {};
		widthStore = {};
		heightStore = {};
		group = {};
		inGroup = {};
		sections: Array<any> = [{}];
		displayItems = [];
		storedItemsLength = 0;
		currentSectionCount = 0;
		maxHeight = 0;
		screenHeight = 0;
		scrollTop = 0;
		additionalDistance = 500;

		containerWidth = 0;
		containerResizeObserver: ResizeObserver = null;

		$refs: {
			refMasonryContainer: HTMLDivElement;
		};

		@Watch('items')
		itemsChanged() {
			if (this.items.length <= this.storedItemsLength) {
				this.renderMasonry();
			} else {
				this.computePosition();
				this.setMaxHeight();
				this.setDisplay();
			}
			this.storedItemsLength = this.items.length;
		}

		@Watch('columns')
		columnsChanged() {
			this.renderMasonry();
		}

		@Watch('colWidth')
		colWidthChanged() {
			this.renderMasonry();
		}

		get containerStyle() {
			const height = `${this.maxHeight || 0}px`;
			const display = Object.keys(this.positionMap).length ? `block` : `none`;
			return {
				height,
				display,
			};
		}

		getKey(item, index) {
			return this.recycleNode ? index : item._masonryIndex;
		}

		getContainerOffset() {
			if (!this.$refs.refMasonryContainer) {
				return 0;
			}
			const bodyRect = document.documentElement.getBoundingClientRect();
			const elRect = this.$refs.refMasonryContainer.getBoundingClientRect();
			return elRect.top - bodyRect.top;
		}

		getItemStyles(idx) {
			return {
				width: `${this.colWidth}px`,
				height: `${this.positionMap[idx]?.height ?? 0}px`,
				transform: `translateX(${this.positionMap[idx]?.left ?? 0}px) translateY(${this.positionMap[idx]?.top ?? 0}px)`,
			};
		}

		renderMasonry() {
			this.resetGroup();
			this.resetSections();
			this.resetWidthStore();
			this.resetHeightStore();
			this.resetPositionMap();
			this.setMaxHeight();
			this.setDisplay();
		}

		setDisplay() {
			const countPerSection = this.rowsPerSection * this.columns;
			const showCondHead = this.scrollTop - this.additionalDistance;
			const showCondTail = this.scrollTop + this.screenHeight + this.additionalDistance;
			const start = Math.floor(showCondHead / this.groupSize);
			const end = Math.floor(showCondTail / this.groupSize);

			let list = [];
			const inList = {};

			for (let i = start; i <= end; i++) {
				if (!this.group[i]) {
					continue;
				}
				for (let j = 0; j < this.group[i].length; j++) {
					const idx = this.group[i][j];
					if (inList[idx]) {
						continue;
					}
					list = list.concat(this.items.slice(idx * countPerSection, (idx + 1) * countPerSection));
					inList[idx] = true;
				}
			}

			if (window.requestAnimationFrame) {
				window.requestAnimationFrame(() => {
					this.displayItems = list;
					this.$forceUpdate();
				});
			} else {
				this.displayItems = list;
				this.$forceUpdate();
			}
		}

		resetWidthStore() {
			this.widthStore = {};
			for (let i = 0; i < this.columns; i++) {
				this.widthStore[i] = (this.colWidth + this.columnGap) * i;
			}
		}

		resetHeightStore() {
			this.heightStore = {};
			for (let i = 0; i < this.columns; i++) {
				this.heightStore[i] = 0;
			}
		}

		resetPositionMap() {
			this.displayItems = this.items;
			this.positionMap = {};
			this.computePosition();
		}

		resetGroup() {
			this.group = {};
			this.inGroup = {};
		}

		resetSections() {
			this.sections = [{}];
			this.currentSectionCount = 0;
		}

		computePosition() {
			if (!this.widthStore || !this.heightStore || !this.columns) {
				return;
			}
			const countPerSection = this.columns * this.rowsPerSection;
			this.items.forEach((item, index) => {
				const mapKey = index;
				item._masonryIndex = index;
				if (this.positionMap[mapKey]) {
					return;
				}
				this.$set(this.positionMap, mapKey, {});
				// compute load height
				const h = this.itemHeightGetter(item, this.colWidth);
				this.$set(this.positionMap[mapKey], 'height', h);
				// compute position
				let left;
				let top;
				let storeIdx;
				if (index < this.columns) {
					left = this.widthStore[index];
					top = 0;
					storeIdx = index;
				} else {
					const minHeightIdx = this.getMinHeightCol();
					left = this.widthStore[minHeightIdx];
					top = this.heightStore[minHeightIdx];
					storeIdx = minHeightIdx;
				}
				// check section count
				if (this.currentSectionCount < countPerSection) {
					this.currentSectionCount += 1;
				} else {
					this.currentSectionCount = 1;
					this.sections.push({});
				}
				// set position
				this.$set(this.positionMap[mapKey], 'left', left);
				this.$set(this.positionMap[mapKey], 'top', top);
				this.heightStore[storeIdx] += h + this.rowGap;
				// set position to section
				const sectionIdx = this.sections.length - 1;
				const { head, tail } = this.sections[sectionIdx];
				if (typeof head === 'undefined' || top < head) {
					this.sections[sectionIdx].head = top;
				}
				const bottom = top + h;
				if (typeof tail === 'undefined' || bottom > tail) {
					this.sections[sectionIdx].tail = bottom;
				}
			});
			this.sections.forEach((section, idx) => {
				// 把所有的section放到groupMap里面
				if (this.inGroup[idx]) {
					return;
				}
				const { head, tail } = section;
				if (typeof head === 'undefined' || typeof tail === 'undefined') {
					return;
				}
				const start = Math.floor(head / this.groupSize);
				const end = Math.floor(tail / this.groupSize);
				for (let i = start; i <= end; i++) {
					if (!this.group[i]) {
						this.group[i] = [];
					}
					this.group[i].push(idx);
				}
				this.inGroup[idx] = true;
			});
		}

		getMinHeightCol() {
			let min = Number.MAX_VALUE;
			let minIndex = 0;
			for (let i = 0; i < this.columns; i++) {
				if (this.heightStore[i] < min) {
					min = this.heightStore[i];
					minIndex = i;
				}
			}
			return minIndex;
		}

		getMaxHeight() {
			let max = 0;
			for (let i = 0; i < this.columns; i++) {
				if (this.heightStore[i] > max) {
					max = this.heightStore[i];
				}
			}
			return max;
		}

		setMaxHeight() {
			this.maxHeight = this.getMaxHeight();
		}

		handleWindowResize() {
			this.screenHeight = document.documentElement.clientHeight;
		}

		handleWindowScroll() {
			this.handleScroll();
		}

		handleScroll() {
			this.scrollTop = document.documentElement.scrollTop - this.getContainerOffset();
			this.setDisplay();
		}

		observeWidthChange() {
			const resizeObserver = new ResizeObserver((entries) => {
				const width = entries[0]?.borderBoxSize?.[0].inlineSize;
				if (typeof width === 'number' && width !== this.containerWidth) {
					this.containerWidth = width;
					this.colWidth = Math.floor((this.containerWidth - (this.columns - 1) * this.columnGap) / this.columns);
				}
			});

			resizeObserver.observe(this.$refs.refMasonryContainer);
			this.containerResizeObserver = resizeObserver;
		}

		created() {
			this.resetWidthStore();
			this.resetHeightStore();
			this.resetPositionMap();
		}

		mounted() {
			this.screenHeight = document.documentElement.clientHeight;
			this.scrollTop = document.documentElement.scrollTop;

			window.addEventListener('resize', this.handleWindowResize);
			window.addEventListener('scroll', this.handleWindowScroll, true);
			this.observeWidthChange();
		}

		beforeDestroy() {
			window.removeEventListener('resize', this.handleWindowResize);
			window.removeEventListener('scroll', this.handleWindowScroll);
			if (this.containerResizeObserver) {
				this.containerResizeObserver.disconnect();
			}
		}
	}
