
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import ThumbnailOnlyLoader from '@pixcap/ui-core/components/content-loaders/ThumbnailOnlyLoader.vue';

	@Component({
		name: 'LibraryItemListLoader',
		components: {
			ThumbnailOnlyLoader,
		},
	})
	export default class LibraryItemListLoader extends Vue {
		@Prop({ default: false }) readonly isHorizontalScroll: boolean;
	}
