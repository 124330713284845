
	import { Component, Prop, Vue } from 'vue-property-decorator';

	@Component({
		name: 'MasonryWallLoader',
	})
	export default class MasonryWallLoader extends Vue {
		@Prop({ default: 4 }) columns: number;
		@Prop({ default: 40 }) gap: number;
	}
