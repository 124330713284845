
	import { Vue, Component, Prop } from 'vue-property-decorator';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';

	@Component({
		name: 'PageHeading',
		components: { BodyText, HeadingText },
	})
	export default class PageHeading extends Vue {
		@Prop() title: string;
		@Prop() description: string;

		isRendering = true;

		mounted() {
			this.isRendering = false;
		}
	}
