
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import { mapState } from 'vuex';
	import FilterInput from '@/components/pixcap-library/FilterInput.vue';
	import { mutationsWrapper as LibraryMutations } from '@/store/pixcapassets/wrapper';
	import { IAssetsLibraryState, NAMESPACE as LIBRARY_NAMESPACE } from '@/models/store/pixcapassets.interface';

	@Component({
		name: 'LibraryFilterAnimatedOptions',
		components: {
			FilterInput,
		},
		computed: {
			...mapState(LIBRARY_NAMESPACE, {
				showOnlyAnimatedFiles: (state: IAssetsLibraryState) => state.showOnlyAnimatedFiles,
			}),
		},
	})
	export default class LibraryFilterAnimatedOptions extends Vue {
		@Prop({ default: 'right' }) openPosition: string;
		@Prop({ default: 'default' }) size: 'small' | 'default';

		showOnlyAnimatedFiles: IAssetsLibraryState['showOnlyAnimatedFiles'];

		filterOptions = [
			{
				name: this.$t('common_elements.filter.type.animated_static'),
				value: null,
			},
			{
				name: this.$t('common_elements.filter.type.animated'),
				value: true,
			},
			{
				name: this.$t('common_elements.filter.type.static'),
				value: false,
			},
		];

		get activeOption() {
			const filterOptions = this.filterOptions;
			return filterOptions.find((option) => option.value === this.showOnlyAnimatedFiles) || filterOptions[0];
		}

		handleChangeFilter(value: boolean | null) {
			LibraryMutations.updateShowOnlyAnimatedFiles(this.$store, value);
			this.$emit('onFilterChange', value);
		}
	}
