
	import { Component, Vue } from 'vue-property-decorator';
	import AuthenticationLayout from '@pixcap/ui-core/components/account/authentication/AuthenticationLayout.vue';
	import { actionsWrapper as UserActions } from '@pixcap/ui-core/store/user';
	import { mutationsWrapper as OrganismMutations } from '@/store/organism/wrapper';
	@Component({
		name: 'AuthWall',
		components: { AuthenticationLayout },
	})
	export default class AuthWall extends Vue {
		onSignInSuccessfully() {
			if (window.parent.origin == window.origin) {
				window.parent.postMessage('login_successfully', '*');
			}
			const onAuthenticated = this.$pixcap.$services.authService.getOnAuthenticated();
			UserActions.fetchPersonalData(this.$store).then(({ subscriptionInfo }) => {
				if (onAuthenticated) {
					onAuthenticated(subscriptionInfo);
				}
			});
			OrganismMutations.setShowAuthWall(this.$store, false);
			try {
				const redirectPath = new URLSearchParams(window.location.search).get('redirect');
				if (redirectPath) window.location.href = redirectPath;
			} catch {}
		}
	}
