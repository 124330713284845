
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import { mapState } from 'vuex';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import { IAssetsLibraryState, NAMESPACE as LIBRARY_NAMESPACE, SEARCH_ITEM_TYPE, SEARCH_TAB } from '@/models/store/pixcapassets.interface';

	@Component({
		name: 'ExploreLibraryEmptyState',
		components: {
			BodyText,
			HeadingText,
		},
		computed: {
			...mapState(LIBRARY_NAMESPACE, {
				activeSearchItemType: (state: IAssetsLibraryState) => state.activeSearchItemType,
				showPackOrElement: (state: IAssetsLibraryState) => state.showPackOrElement,
			}),
		},
	})
	export default class ExploreLibraryEmptyState extends Vue {
		@Prop() message: boolean;
		@Prop() withoutPadding: boolean;
		@Prop() readonly searchString: string;

		activeSearchItemType: IAssetsLibraryState['activeSearchItemType'];
		showPackOrElement: IAssetsLibraryState['showPackOrElement'];

		get thumbnailUrl() {
			if (this.searchString) return `${process.env.ROOT_URL}/cdn/app/empty-search-result.mp4`;
			return `${process.env.ROOT_URL}/cdn/app/empty-box.mp4`;
		}

		get assetType() {
			if (this.showPackOrElement === SEARCH_TAB.PACKS) {
				switch (this.activeSearchItemType) {
					case SEARCH_ITEM_TYPE.THREE_D_ICONS:
						return 'icon packs';
					case SEARCH_ITEM_TYPE.THREE_D_CHARACTERS:
						return 'character packs';
					case SEARCH_ITEM_TYPE.MOCKUPS:
						return 'mockup packs';
					case SEARCH_ITEM_TYPE.ALL_ASSETS:
					default:
						return 'asset packs';
				}
			}
			switch (this.activeSearchItemType) {
				case SEARCH_ITEM_TYPE.THREE_D_ICONS:
					return 'icons';
				case SEARCH_ITEM_TYPE.THREE_D_CHARACTERS:
					return 'characters';
				case SEARCH_ITEM_TYPE.MOCKUPS:
					return 'mockups';
				case SEARCH_ITEM_TYPE.ALL_ASSETS:
				default:
					return 'assets';
			}
		}

		handleOpenRequestPage() {
			window.open(`https://pixcap.canny.io/3d-graphics-requests`, '_blank');
		}
	}
