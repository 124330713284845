
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import IconLargeArrowRight from '@pixcap/ui-library/components/Icons/IconLargeArrowRight.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import IconCrown from '@pixcap/ui-library/components/Icons/IconCrown.vue';
	import { LIBRARY_TAB } from '@/models/store/pixcapassets.interface';

	@Component({
		name: 'AssetEmptyState',
		components: {
			IconCrown,
			Button,
			IconLargeArrowRight,
			BodyText,
			HeadingText,
		},
	})
	export default class AssetEmptyState extends Vue {
		@Prop() type: 'projects' | 'assets';
		@Prop() withoutPadding: boolean;
		@Prop() readonly filtered: boolean;

		get isProjectsPage() {
			return this.type === 'projects';
		}

		get textWithFilter() {
			return {
				title: this.$t('common_elements.empty.with_search.message'),
				description: this.$t('page.my_assets.downloads.empty_state.text_search'),
			};
		}

		get textWithoutFilter() {
			if (this.isProjectsPage) {
				return {
					title: this.$t('page.my_projects.empty_state.title'),
					description: this.$t('page.my_projects.empty_state.description'),
				};
			}
			return {
				title: this.$t('page.my_assets.downloads.empty_state.title'),
				description: this.$t('page.my_assets.downloads.empty_state.description'),
			};
		}

		get thumbnailUrl() {
			if (this.filtered) return `${process.env.ROOT_URL}/cdn/app/empty-search-result.mp4`;
			return `${process.env.ROOT_URL}/cdn/app/empty-box.mp4`;
		}

		handleOpenExplore() {
			this.$router.push({ name: 'LibraryPage', params: { tab: LIBRARY_TAB.THREE_D_PACKS } });
		}
	}
