
	import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

	type Column = number[];
	type NonEmptyArray<T> = [T, ...T[]];

	@Component({
		name: 'MasonryWall',
	})
	export default class MasonryWall extends Vue {
		@Prop({ default: 400 }) columnWidth?: number | NonEmptyArray<number>;
		@Prop() items: unknown[];
		@Prop({ default: 0 }) columnGap?: number;
		@Prop({ default: 0 }) rowGap?: number;
		@Prop({ default: false }) isInfiniteScroll?: boolean;
		@Prop({ default: 0 }) ssrColumns?: number;
		@Prop({ default: 1 }) minColumns?: number;
		@Prop({ default: undefined }) maxColumns?: number;

		columns: Column[] = [];

		$refs: {
			wall: HTMLElement;
		};

		@Watch('items')
		handlerItems(next, old) {
			if (!this.isInfiniteScroll || old.length === 0) {
				this.redraw(true);
			} else if (next.length === 0) this.redraw(true);
			// For infinity scroll
			else this.redraw(false, old.length + 1);
		}

		@Watch('columnWidth')
		@Watch('gap')
		@Watch('maxColumns')
		handlerColumnChanges() {
			this.redraw();
		}

		@Watch('minColumns')
		handlerMinColumnChanges() {
			this.redraw(true);
		}

		countIteratively(containerWidth: number, gap: number, count: number, consumed: number): number {
			const nextWidth = this.getColumnWidthTarget(count);
			if (consumed + gap + nextWidth <= containerWidth) {
				return this.countIteratively(containerWidth, gap, count + 1, consumed + gap + nextWidth);
			}
			return count;
		}

		getColumnWidthTarget(columnIndex: number): number {
			const widths = Array.isArray(this.columnWidth) ? this.columnWidth : [this.columnWidth];
			return widths[columnIndex % widths.length] as number;
		}

		columnCount(): number {
			const count = this.countIteratively(this.$refs?.wall?.getBoundingClientRect().width, this.columnGap, 0, -this.columnGap);
			const boundedCount = this.aboveMin(this.belowMax(count));
			return boundedCount > 0 ? boundedCount : 1;
		}

		belowMax(count: number): number {
			const max = this.maxColumns;
			if (!max) {
				return count;
			}
			return count > max ? max : count;
		}

		aboveMin(count: number): number {
			const min = this.minColumns;
			if (!min) {
				return count;
			}
			return count < min ? min : count;
		}

		createColumns(count: number): Column[] {
			return Array.from({ length: count }).map(() => []);
		}

		async fillColumns(itemIndex: number) {
			if (itemIndex >= this.items.length) {
				return;
			}
			await this.$nextTick();
			const columnCount = this.columnCount();
			const assignedColumn = itemIndex % columnCount;
			this.columns[assignedColumn].push(itemIndex);
			await this.fillColumns(itemIndex + 1);
		}

		async redraw(force = false, startFrom = 0) {
			this.$emit('startRedraw');
			if (!force && startFrom) {
				await this.fillColumns(startFrom);
			} else {
				this.columns = this.createColumns(this.columnCount());
				await this.fillColumns(startFrom);
			}
			this.$emit('redrawn');
		}

		mounted() {
			if (this.ssrColumns > 0) {
				const newColumns = this.createColumns(this.ssrColumns);
				this.items.forEach((_, i: number) => newColumns[i % this.ssrColumns]!.push(i));
				this.columns = newColumns;
			}
			window.addEventListener('resize', () => this.redraw());
		}

		beforeDestroy() {
			window.removeEventListener('resize', () => this.redraw());
		}
	}
