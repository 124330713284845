
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { mapState } from 'vuex';
	import { mutationsWrapper as LibraryMutations } from '@/store/pixcapassets/wrapper';
	import { IAssetsLibraryState, LIBRARY_TAB, NAMESPACE as LIBRARY_NAMESPACE } from '@/models/store/pixcapassets.interface';
	import LibraryTabs from '@/components/pixcap-library/library-files/LibraryTabs.vue';

	@Component({
		name: 'LibraryFilterSearchItemType',
		components: {
			LibraryTabs,
		},
		computed: {
			...mapState(LIBRARY_NAMESPACE, {
				activeExplorerTab: (state: IAssetsLibraryState) => state.activeExplorerTab,
			}),
		},
	})
	export default class LibraryFilterSearchItemType extends Vue {
		@Prop({ default: 'right' }) openPosition: string;
		@Prop({ default: 'default' }) size: 'small' | 'default';

		activeExplorerTab: IAssetsLibraryState['activeExplorerTab'];

		filterOptions = [
			{
				name: this.$t('common_elements.all_assets'),
				value: LIBRARY_TAB.ALL_ASSETS,
			},
			{
				name: this.$t('common_elements.icons'),
				value: LIBRARY_TAB.THREE_D_PACKS,
			},
			{
				name: this.$t('common_elements.mockups'),
				value: LIBRARY_TAB.MOCKUPS,
			},
			{
				name: this.$t('common_elements.characters'),
				value: LIBRARY_TAB.THREE_D_CHARACTERS,
			},
		];

		get activeOption() {
			const filterOptions = this.filterOptions;
			return filterOptions.find((option) => option.value === this.activeExplorerTab) || filterOptions[0];
		}

		handleChangeFilter(value: LIBRARY_TAB) {
			LibraryMutations.setExplorerTab(this.$store, value);
			if (value === LIBRARY_TAB.THREE_D_CHARACTERS) {
				LibraryMutations.updateShowOnlyAnimatedFiles(this.$store, null);
			}
			if (this.$route.name === 'LibraryPage') {
				const libraryRoute = this.$router.resolve(value);
				window.history.replaceState({}, '', libraryRoute.href);
			} else {
				this.$router.push({ name: 'LibraryPage', params: { tab: value } });
			}
		}
	}
