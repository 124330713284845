var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"masonry-wall-loader",style:({ display: 'flex', gap: `${_vm.gap}px` })},_vm._l((_vm.columns),function(column,columnIndex){return _c('div',{key:columnIndex,staticClass:"masonry-wall-column",style:({
			display: 'flex',
			'flex-basis': '400px',
			'flex-direction': 'column',
			'flex-grow': 1,
			gap: `${_vm.gap}px`,
			height: ['-webkit-max-content', '-moz-max-content', 'max-content'],
			'min-width': 0,
		}),attrs:{"data-index":columnIndex}},_vm._l((3),function(index){return _c('div',{key:index,staticClass:"masonry-item"},[_c('div',{staticClass:"item-loader loading-state-animation"})])}),0)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }