
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import IntersectComponent from '@pixcap/ui-core/plugins/intersect-component/IntersectComponent.vue';
	import ProItemTag from '@pixcap/ui-core/components/common/ProItemTag.vue';
	import NewBadge from '@pixcap/ui-core/components/common/NewBadge.vue';
	import { PixcapLibraryPack as PixcapLibraryPackType } from '@/models/store/pixcapassets.interface';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import {
		Getters as UserGetters,
		GetterTypes as UserGetterTypes,
		NAMESPACE as USER_NAMESPACE,
		UserState,
	} from '@pixcap/ui-core/models/store/user.interface';
	import { mapGetters, mapState } from 'vuex';
	import AnimatedThumbnail from '@/components/pixcap-library/shared/AnimatedThumbnail.vue';

	@Component({
		name: 'PixcapLibraryPack',
		components: {
			AnimatedThumbnail,
			ProItemTag,
			NewBadge,
			BodyText,
			CaptionText,
			IntersectComponent,
		},
		computed: {
			...mapState(USER_NAMESPACE, {
				personalData: (state: UserState) => state.personalData,
			}),
			...mapGetters(USER_NAMESPACE, {
				isProOrAbove: UserGetterTypes.IS_PRO_OR_ABOVE,
			}),
		},
	})
	export default class PixcapLibraryPack extends Vue {
		@Prop() asset: PixcapLibraryPackType;
		@Prop({ default: false }) isBrandKit: boolean;

		personalData: UserState['personalData'];
		isProOrAbove: ReturnType<UserGetters[UserGetterTypes.IS_PRO_OR_ABOVE]>;

		isInViewPort = true;
		showAnimation = true;

		get showNewBadge() {
			const currentDate = new Date();
			const packCreatedDate = new Date(this.asset.createdAt);
			const newBadgePeriod = new Date(packCreatedDate.setDate(new Date().getDate() + 7));
			return newBadgePeriod > currentDate;
		}

		get isPurchased() {
			if (this.asset.packItemIds) {
				const itemIds = this.asset.packItemIds.map((id) => id);
				return itemIds.every((id) => this.personalData?.user?.purchaseHistory?.includes(id));
			} else {
				const itemIds = this.asset.packItems.map((item) => item.itemId);
				return itemIds.every((id) => this.personalData?.user?.purchaseHistory?.includes(id));
			}
		}

		get isOwned() {
			if (this.isProOrAbove) return true;
			if (this.asset.packItemIds) {
				const itemIds = this.asset.packItemIds.map((id) => id);
				return itemIds.every((id) => this.personalData?.user?.purchaseHistory?.includes(id));
			} else {
				const itemIds = this.asset.packItems.map((item) => item.itemId);
				return itemIds.every((id) => this.personalData?.user?.purchaseHistory?.includes(id));
			}
		}

		get previewLink() {
			if (this.isBrandKit) {
				return { name: 'LibraryBrandKitPackDetailsPage', params: { packSlug: this.asset.slug } };
			}
			return { name: 'LibraryPackDetailsPage', params: { packSlug: this.asset.slug } };
		}

		get thumbnailAltTag() {
			const altTag = `${this.asset.packSize} ${this.asset.packName} 3d pack of graphics and illustrations`;
			return altTag;
		}

		get thumbnailTitleTag() {
			return `Download ${this.asset.packName} 3d pack of graphics and illustrations in PNG, GLB for graphic design projects`;
		}

		handleMouseLeave() {
			if (this.$route.name === 'LibraryPage' || this.$route.name === 'LibraryExploreSearchList') this.showAnimation = false;
			else this.showAnimation = true;
		}

		created() {
			if (this.$route.name === 'LibraryPage' || this.$route.name === 'LibraryExploreSearchList') this.showAnimation = false;
		}
	}
