
	import { Component, Vue, Prop, InjectReactive } from 'vue-property-decorator';
	import IconArrowLeft from '@pixcap/ui-library/components/Icons/IconArrowLeft.vue';
	import IconArrowRight from '@pixcap/ui-library/components/Icons/IconArrowRight.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';

	type PageNode = {
		value: number;
		isExpand: boolean;
	};

	@Component({
		name: 'Pagination',
		components: {
			IconArrowLeft,
			IconArrowRight,
			BodyText,
		},
	})
	export default class Pagination extends Vue {
		@Prop() readonly currentPage: number;
		@Prop() readonly totalPages: number;

		@InjectReactive() isMobileScreen: boolean;

		get mobilePageNodes() {
			const nodes: PageNode[] = [];
			if (this.totalPages <= 4) {
				for (let i = 0; i < this.totalPages; i++) {
					nodes.push({ value: i, isExpand: false });
				}
				return nodes;
			}
			if (this.currentPage < 3) {
				for (let i = 0; i < 4; i++) {
					nodes.push({ value: i, isExpand: false });
				}
				nodes.push({ value: 4, isExpand: true });
				return nodes;
			}
			if (this.currentPage + 3 >= this.totalPages) {
				nodes.push({ value: this.totalPages - 6, isExpand: true });
				for (let i = this.totalPages - 4; i < this.totalPages; i++) {
					nodes.push({ value: i, isExpand: false });
				}
				return nodes;
			}
			nodes.push({ value: this.currentPage - 2, isExpand: true });
			nodes.push({ value: this.currentPage - 1, isExpand: false });
			nodes.push({ value: this.currentPage, isExpand: false });
			nodes.push({ value: this.currentPage + 1, isExpand: false });
			nodes.push({ value: this.currentPage + 2, isExpand: true });
			return nodes;
		}

		get pageNodes() {
			const nodes: PageNode[] = [];
			if (this.totalPages <= 7) {
				for (let i = 0; i < this.totalPages; i++) {
					nodes.push({ value: i, isExpand: false });
				}
				return nodes;
			}
			if (this.currentPage < 4) {
				for (let i = 0; i < 5; i++) {
					nodes.push({ value: i, isExpand: false });
				}
				nodes.push({ value: 5, isExpand: true });
				nodes.push({ value: this.totalPages - 1, isExpand: false });
				return nodes;
			}
			if (this.currentPage + 4 >= this.totalPages) {
				nodes.push({ value: 0, isExpand: false });
				nodes.push({ value: this.totalPages - 6, isExpand: true });
				for (let i = this.totalPages - 5; i < this.totalPages; i++) {
					nodes.push({ value: i, isExpand: false });
				}
				return nodes;
			}
			nodes.push({ value: 0, isExpand: false });
			nodes.push({ value: this.currentPage - 2, isExpand: true });
			nodes.push({ value: this.currentPage - 1, isExpand: false });
			nodes.push({ value: this.currentPage, isExpand: false });
			nodes.push({ value: this.currentPage + 1, isExpand: false });
			nodes.push({ value: this.currentPage + 2, isExpand: true });
			nodes.push({ value: this.totalPages - 1, isExpand: false });
			return nodes;
		}
	}
